import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { SchedulerExpansionType, getExpansionPanel } from "./utils";
import { includes, isEmpty } from "lodash";
import { ExpansionPanelEntity } from "@/models/persons/v10/Implements";
import { ReportDataEntity, ResourceDataEntity } from "@/interfaces/report";
import { ActivePanelTab } from "@/interfaces/persons/v10/types";
import { ReportResourceDataEntity } from "@/models/Reports/v2/Report";
import { scrollToTop } from "@/utils/services-global";
import Alertize from "@/components/Alertize.vue";
import Separator from "@/components/Content/Separator.vue";
import CardCollapsePanel from "@/views/Admin/Persons/V10/Dependencies/Panels/CardCollapsePanel/index.vue";
import SchedulerList from "@/views/Admin/Reports/V2/Dependencies/Schedulers/List/index.vue";
import SchedulerForm from "@/views/Admin/Reports/V2/Dependencies/Schedulers/Form/index.vue";
import ReportType from "@/views/Admin/Reports/V2/Form/Sections/ReportType/index.vue";
import ReportFilters from "@/views/Admin/Reports/V2/Form/Sections/ReportFilters/index.vue";
import { getDimensionMetricParsed } from "@/models/Reports/v2/utils";
import { ScheduleEntity } from "@/models/Reports/v2/Entity";
import { PayloadResource } from "@/interfaces/reports/v2/payload";
import { TypeLoading } from "@/interfaces/loading";

export default Vue.extend({
	name: "ReportScheduler",
	props: {},
	components: {
		Alertize,
		Separator,
		CardCollapsePanel,
		SchedulerList,
		SchedulerForm,
		ReportType,
		ReportFilters,
	},
	data: () => ({
		activePanel: [
			ActivePanelTab.SCHEDULER_REPORT,
			ActivePanelTab.SAVE_SCHEDULE,
		],
		itemsExpansion: [] as ExpansionPanelEntity[],
		dataReportEdit: [],
	}),
	created() {
		this.$nextTick(async () => {
			try {
				this.itemsExpansion = await getExpansionPanel();
			} catch (error) {
				console.error("ReportScheduler:error", error);
			}
		});
	},
	async mounted() {
		this.$nextTick(async () => {});
	},
	computed: {
		...mapGetters("account", ["isAccountMaster"]),
		...mapGetters("generate_report", ["attempResponse"]),
		...mapGetters("report_v2", [
			"storeSchedule",
			"storeReport",
			"storeResources",
			"storeResourceData",
		]),
		getStoreSchedule(): ScheduleEntity {
			return this.storeSchedule;
		},
		getStoreReport(): ReportDataEntity {
			return this.storeReport;
		},
		getStoreResources(): ResourceDataEntity {
			return this.storeResources;
		},
		getStoreResourceData(): ReportResourceDataEntity {
			return this.storeResourceData;
		},
		getExpansionType() {
			return SchedulerExpansionType;
		},
		getPanelsItems() {
			let panels: ExpansionPanelEntity[] = this.itemsExpansion;

			const isEdit: Boolean = this.getStoreSchedule.form.hasID();

			/**
			 * Modo edicion {isEdit}
			 * En el modo edicion no mostrar el listado de scheduler
			 */
			if (isEdit) {
				panels = panels.filter(
					(p) =>
						p.type !==
						(SchedulerExpansionType.SCHEDULER_REPORT as any)
				);

				this.addActivePanels();
			}

			return panels;
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("report_v2", [
			"scheduleShow",
			"setScheduleEdit",
			"matchDataScheduleShow",
			"setSPResources",
		]),

		addActivePanels() {
			this.activePanel = [
				ActivePanelTab.SCHEDULER_REPORT,
				ActivePanelTab.SAVE_SCHEDULE,
			];
		},

		isExpanded(itemId: number) {
			return this.activePanel.includes(itemId);
		},
		isSection(itemType: string, type: string) {
			return itemType === type;
		},
		async updateReport(params: any) {
			try {
				this.setLoadingData(TypeLoading.loading);

				/**
				 * Fetch de show:scheduler
				 */
				let scheduleShow = await this.scheduleShow(params.id);

				scrollToTop();

				this.setLoadingData();

				/**
				 * Match de datos del result de edit scheduler
				 */
				scheduleShow = await this.matchDataScheduleShow({
					params,
					scheduleShow,
				});
				
				this.attempResponse.setSuccess(true);

				/**
				 * Extraer y preparar el payload para {fetchResourceSP}
				 * Fetch de {fetchResourceSP} y set de resources en el store
				 */
				await this.setSPResources({
					params,
					scheduleShow,
				});

				/**
				 * Preparar los filtros filtrados por los datos de scheduler show
				 */
				const reportFiltered: PayloadResource = new PayloadResource(
					params.filters,
					this.storeResources
				);
				const appliedFilters = await reportFiltered.applyFilters();

				/**
				 * Aplicar filtros en el store de report
				 */
				await this.storeReport.setFilters(appliedFilters);
				
				/**
				 * Obtener los keys de los filtros
				 */
				const keys = await this.storeSchedule.form.prepareFilters(
					appliedFilters
				);
				
				/**
				 * Aplicar filtros [key] a form scheduler
				 */
				await this.storeSchedule.form.setFiltersKeys(keys);

				/**
				 * Parsed y Set de dimensiones y metricas
				 */
				await this.storeReport.setDimensionMetric({
					dimensions: await getDimensionMetricParsed(
						params.dimensions
					),
					metrics: await getDimensionMetricParsed(params.metrics),
				});

				this.attempResponse.setSuccess(true);
			} catch (error) {
				this.setLoadingData();
				console.error(`ReportScheduler::updateReport`, error);
			}
		},
		async isSelectedAll(value: Array<number>) {
			return includes(value, 0);
		},

		async handleAll(params: { key: any; value: Array<any> }) {
			this.getStoreResourceData.selected_all[params.key] = params.value;

			const items: Array<any> = (await this.isSelectedAll(params.value))
				? this.resources[params.key]
				: [];

			this.getStoreReport.filters[params.key] = items;
		},
		async handlerFocus(event: any) {
			const { key } = event;
			const emitterKey: string =
				key === "account" ? "get-account-sp" : "fetch-combo";

			const invalidAccount: Boolean =
				this.isAccountMaster &&
				key !== "account" &&
				isEmpty(this.getStoreReport.filters.account);

			if (invalidAccount) {
				this.snackbar = true;
				this.texto_snackbar = this.$t("report.messages.account");
			} else {
				this.$emit(emitterKey, key);
			}
		},
	},
	watch: {},
});
