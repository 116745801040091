import { SnactType } from "@/interfaces/snack";
import { SnacktifyEntity } from "@/models/Core/Snacktify";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Getter, Mutation } from "vuex-class";

@Component({
	components: {},
	mixins: [],
})
export default class Snacktify extends Vue {
	@Getter("snacktify/getterSnacktify") getSnacktify!: SnacktifyEntity;

	@Mutation("snacktify/SET_SNACKTIFY_DATA") setSnacktifyData!: (payload?: {
		type?: SnactType;
		message?: string;
		messageParams?: Record<any, any>;
	}) => void;

	public get getModel() {
		return this.getSnacktify.open;
	}

	public get getMessage() {
		return this.getSnacktify.message;
	}

	public async handleClose() {
		this.setSnacktifyData();
		this.getSnacktify.close();
	}
}
