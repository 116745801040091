import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
	components: {},
	mixins: [],
})
export default class CountDownTimer extends Vue {
	@Prop({ required: false, default: 0 }) public value!: number; // Tiempo total en segundos
	@Prop({ required: false, default: true }) public showRemaining!: boolean; // Mostrar el valor numberico

	public secondsRemaining: number = this.value; // Segundos restantes
	private progress: number = 100; // Porcentaje de progreso
	private timer: NodeJS.Timeout | null = null; // Referencia al temporizador

	private startCountdown(): void {
		if (this.timer) {
			clearInterval(this.timer);
		}

		this.timer = setInterval(() => {
			if (this.secondsRemaining > 0) {
				this.secondsRemaining--;
				this.progress =
					((this.value - this.secondsRemaining) / this.value) * 100;
			} else {
				if (this.timer) {
					clearInterval(this.timer);
					this.timer = null;
				}
			}
		}, 1000);
	}

	public formatTime(seconds: number): string {
		if (!this.showRemaining) return "";
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		return `${minutes}:${
			remainingSeconds < 10 ? "0" : ""
		}${remainingSeconds}`;
	}

	public get getProgressValue() {
		return 100 - this.progress;
	}

	mounted(): void {
		this.$nextTick(() => {
			this.startCountdown();
		});
	}

	@Emit("remaining") onRemaining(val: number) {
		return val;
	}

	@Watch("secondsRemaining")
	private onSecondsRemaining(val: number, old: number): void {
		this.onRemaining(val);
	}
}
