import Vue from "vue";
import DialogDefault from "@/components/Content/Dialogs/Default.vue";
import { FiltersLabels } from "@/views/Admin/Reports/V2/utils/reportsUtils";
import CardChip from "@/views/Admin/Reports/V2/Dependencies/Chips/CardChip.vue";
import DateCard from "@/views/Admin/Reports/V2/Dependencies/Chips/DateCard.vue";

export default Vue.extend({
	name: "ModalFilter",

	props: {
		type: {
			type: String,
			default: "",
		},
		show: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: "report.title.Filters",
		},
        filters: {
			type: Object,
		},
		DateRange: {
			type: Object,
		},
		accountMaster: {
			type: Boolean,
			default: false,
		},
		custom: {
			type: Boolean,
			default: false,
		},
	},

	components: {
		DialogDefault,
		CardChip,
		DateCard
	},

	data: () => ({
        sections: []
    }),

	created() {},

	async mounted() {
		this.$nextTick(async () => {});
	},

	computed: {
		
        getFilters(){
            return this.filters;
        },

        getSectionsFilters(){
            this.sections = [];
            Object.entries(this.getFilters).forEach(([key, value]) => {
                let dataSections = {
                    key: key,
                    title: FiltersLabels()[key],
                    values: value
                };
                this.sections.push(dataSections);
            });
            return this.sections;
        }
	},

	methods: {
        closeFilters(){
			this.$emit('handler-modal-filter', false);
		},
		handleRemove(event) {
			this.$emit("remove", event);
		},

		showFilter(key: string){
			const accountFilter = "account";
			if(key != accountFilter)return true;
			return this.accountMaster;
		}
	},

	watch: {},
});
