import { ActivePanelTab } from "@/interfaces/persons/v10/types";
import { ExpansionPanelEntity } from "@/models/persons/v10/Implements";

export enum SchedulerExpansionType {
	SCHEDULER_REPORT = "scheduler_report",
	REPORT_TYPE = "report_type",
	REPORT_FILTERS = "report_filters",
	REPORT_DIMENSIONS = "report_dimensions",
	REPORT_METRICS = "report_metrics",
	SAVE_SCHEDULE = "save_schedule",
}

export async function getExpansionPanel(): Promise<ExpansionPanelEntity[]> {
	return [
		new ExpansionPanelEntity({
			id: ActivePanelTab.SCHEDULER_REPORT,
			title: "report.v2.tabs.scheduler.scheduler_report",
			icon: "",
			type: SchedulerExpansionType.SCHEDULER_REPORT as any,
			disabled: false,
			expandable: true,
			required: true,
			loading: false,
			verify: false,
			show: true,
		}),

		new ExpansionPanelEntity({
			id: ActivePanelTab.SAVE_SCHEDULE,
			title: "report.v2.tabs.scheduler.save_schedule",
			icon: "",
			type: SchedulerExpansionType.SAVE_SCHEDULE as any,
			disabled: false,
			expandable: true,
			required: true,
			loading: false,
			verify: false,
			show: true,
		}),
	];
}
