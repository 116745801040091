import Vue from "vue";
import i18n from "@/plugins/i18n";
import { DataDisplayItem, ReportDataEntity } from "@/interfaces/report";
import { mapGetters } from "vuex";
import { GetConditionalMetrics } from "@/views/Admin/Reports/V2/utils/reportsUtils";
import { ScheduleEntity } from "@/models/Reports/v2/Entity";

export default Vue.extend({
	name: "ListData",

	props: {
		values: {
			type: Object,
			default: function () {
				return {};
			},
		},
		type: {
			type: String,
			required: true,
		},
		loading: {
			type: Boolean,
			default: true,
		},
	},

	components: {},

	data: () => ({
		dataDisplay: new Array<DataDisplayItem>(),
		conditionalMetrics: GetConditionalMetrics(),
	}),

	created() {
		this.$nextTick(async () => {
			await this.preparedItemsDisplay();
		});
	},

	mounted() {
		this.$nextTick(async () => {
			await this.preparedItemsDisplay();
		});
	},

	computed: {
		...mapGetters("report_v2", ["storeReport", "storeSchedule"]),

		getStoreSchedule(): ScheduleEntity {
			return this.storeSchedule;
		},

		getStoreReport(): ReportDataEntity {
			return this.storeReport;
		},

		getItems() {
			return this.values;
		},
		getDisplayItems(): DataDisplayItem[] {
			return this.dataDisplay;
		},
		getType() {
			return this.type;
		},

		getSizeDimensions() {
			return this.getStoreReport.size;
		},
	},

	methods: {
		async preparedItemsDisplay() {
			this.dataDisplay = [];
			for (let clave in this.getItems) {
				let parent = {
					key: clave,
					title: i18n.t(`report.v2.${this.type}.${clave}`),
					items: [],
					isParent: true,
					option: false,
				} as DataDisplayItem;
				this.dataDisplay.push(parent);
				for (let subClave in this.getItems[clave]) {
					let subItem = {
						key: subClave,
						title: i18n.t(`report.v2.${this.type}.${subClave}`),
						value: this.getItems[clave][subClave],
						isParent: false,
						option: false,
					} as DataDisplayItem;
					this.dataDisplay.push(subItem);
				}
			}
		},

		async setItemSelected(item: any) {
			if (this.conditionalMetrics.hasOwnProperty(item.key)) {
				const selected = this.conditionalMetrics[item.key].selected;
				selected.forEach((c) => {
					(this.getDisplayItems as DataDisplayItem[]).forEach((d) => {
						if (d.key == c) {
							d.option = item.option;
						}
					});
				});
			}
			this.$emit("set-selected-item", { type: this.type, value: item });
		},

		/**
		 * Cargar dimensiones|metricas del schedule obtenido
		 * @param type
		 */
		async syncData(type: "dimensions" | "metrics") {
			const displayItems: DataDisplayItem[] = this.getDisplayItems;
			const element = this.getStoreReport[type];

			for (const [key, value] of Object.entries(element)) {
				const displayItem: DataDisplayItem | undefined =
					displayItems.find((d) => d.key === key);

				if (displayItem) {
					displayItem.option = Boolean(value);
				}
			}
		},
	},

	watch: {
		values: {
			async handler(val, oldVal) {
				await this.preparedItemsDisplay();
				await this.syncData("dimensions");
				await this.syncData("metrics");
			},
			deep: true,
			immediate: false,
		},
		"getStoreReport.dimensions": {
			async handler(val, oldVal) {
				await this.syncData("dimensions");
			},
			deep: true,
			immediate: false,
		},

		"getStoreReport.metrics": {
			async handler(val, oldVal) {
				await this.syncData("metrics");
			},
			deep: true,
			immediate: false,
		},

		"getStoreReport.report_year": {
			async handler(val, oldVal) {
				await this.preparedItemsDisplay();
				await this.syncData("dimensions");
				await this.syncData("metrics");
			},
			deep: true,
			immediate: false,
		},

		"getStoreReport.date_range": {
			async handler(val, oldVal) {
				await this.preparedItemsDisplay();
				await this.syncData("dimensions");
				await this.syncData("metrics");
			},
			deep: true,
			immediate: false,
		},

		getDisplayItems: {
			async handler(val: DataDisplayItem[]) {
				const selected = val.filter((v) => v.option === true);
				const selectedNumber: Record<string, number> = {};

				selected.forEach((s) => {
					selectedNumber[s.key] = 1;
				});

				const keys: string[] =
					this.getStoreSchedule.form.prepareKeys(selectedNumber);

				this.getStoreSchedule.form[this.type] = keys;
			},
			deep: true,
			immediate: true,
		},
	},
});
