import Vue from "vue";
import { debounce } from "lodash";

export default Vue.extend({
	name: "CardChipModal",

	props: {
		item: {
			type: Object,
			default: function () {
				return {};
			},
		},
        groupStyles: {
			type: String,
			default: "",
		},
		groupClass: {
			type: String,
			default: "",
		},
		canClose: {
			type: Boolean,
			default: false,
		},
		column: {
			type: Boolean,
			default: false,
		},
	},

	components: {},

	data: () => ({}),

	created() {},

	async mounted() {
		this.$nextTick(async () => {});
	},

	computed: {
		getFiltered() {
			return this.item.values;
		},
		getCountFiltered() {
			if (!this.item.values.length) return "0";
			return `${this.item.values.length || "0"}`;
		},
		getNotFountText() {

		},
        getTitle(){
            return this.item.title;
        }
	},

	methods: {
		handleRemove(event) {
			this.$emit("remove", {type: this.item.key, item: event});
		},
	},

	watch: {},
});
