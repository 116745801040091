var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"pt-2 mx-0 px-0 gap grey lighten-3",attrs:{"elevation":"0"}},[_c('Alertize'),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.getStoreSchedule.form.hasID()),expression:"getStoreSchedule.form.hasID()"}],staticClass:"layout-form",attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('Separator',{attrs:{"title":_vm.$t('report.edit')}})],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-expansion-panels',{staticClass:"collapse-panels",attrs:{"accordion":true,"multiple":"","flat":"","tile":""},model:{value:(_vm.activePanel),callback:function ($$v) {_vm.activePanel=$$v},expression:"activePanel"}},[_vm._l((_vm.getPanelsItems),function(item){return [(item.show)?_c('card-collapse-panel',{key:`tab_expansion_${item.id}`,attrs:{"item":item,"expanded":_vm.isExpanded(item.id)}},[(
								_vm.isSection(
									item.type,
									_vm.getExpansionType.SCHEDULER_REPORT
								)
							)?[_c('SchedulerList',{ref:"schedulerList",refInFor:true,attrs:{"showTitle":false},on:{"update-Report":_vm.updateReport}})]:_vm._e(),(
								_vm.isSection(
									item.type,
									_vm.getExpansionType.SAVE_SCHEDULE
								)
							)?[_c('SchedulerForm',{attrs:{"resources":_vm.getStoreResources,"reportData":_vm.dataReportEdit,"edit":false}})]:_vm._e()],2):_vm._e()]})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }