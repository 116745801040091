import {
	FilterProperty,
	Filters,
	FiltersEntity,
	ResourceDataEntity,
} from "@/interfaces/report";
import { isProd } from "@/services/process-service";

export class PayloadResource {
	response_filters: string = "";
	response_parsed: Record<string, any> = {};
	getStoreResources: ResourceDataEntity;

	constructor(filters: string, getStoreResources: ResourceDataEntity) {
		this.response_filters = filters;
		this.response_parsed = JSON.parse(filters);
		this.getStoreResources = getStoreResources;
		//this.debugLog();
	}

	filterResources(resources: any[], filterIds: any[]) {
		return resources?.filter((resource) =>
			filterIds?.some((id) => String(id) === String(resource.key))
		);
	}

	async applyFilters() {
		const account: FilterProperty[] = this.filterResources(
			this.getStoreResources.account,
			this.response_parsed.account
		);

		const filters: Filters = {
			account,
			advertisers: this.filterResources(
				this.getStoreResources.advertisers,
				this.response_parsed.advertisers
			),
			campaigns: this.filterResources(
				this.getStoreResources.campaigns,
				this.response_parsed.campaigns
			),
			line_items: this.filterResources(
				this.getStoreResources.line_items,
				this.response_parsed.line_items
			),
			creatives: this.filterResources(
				this.getStoreResources.creatives,
				this.response_parsed.creatives
			),
			deal_id: this.filterResources(
				this.getStoreResources.deal_id,
				this.response_parsed.deal_id
			),
		};

		let filterEntity: FiltersEntity = new FiltersEntity(account);
		filterEntity.setData(filters);
		return filterEntity;
	}

	debugLog() {
		if (isProd()) return;
		console.debug("[DEBUG]PayloadResource", {
			response_filters: this.response_filters,
			response_parsed: this.response_parsed,
			getStoreResources: this.getStoreResources,
			//applied: this.applyFilters(),
		});
	}
}
