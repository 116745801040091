import Vue from "vue";
import CollapsePanel from "@/components/Content/Collapse/Panel.vue";
import PaginatedChips from "@/views/Admin/Reports/V2/Dependencies/Chips/PaginatedIndex.vue";

export default Vue.extend({
	name: "ReportItems",
	props: {
		item:{
			type: Object,
			required: true
		},
		filters:{
			type: Object,
			required: true
		},
		canClose: {
			type: Boolean,
			default: true,
		},
		expanded: {
			type: Boolean,
			default: false
		},
		id: {
			type: Number,
			required: true
		},
		showFilter:{
			type: Boolean,
			default: false
		},
		icon:{
			type: Object,
			required: false
		},
		showModalFilter:{
			type: Boolean,
			default: false
		},
		showClearFilter:{
			type: Boolean,
			default: false
		},
		loadings: {
			type: Object,
		},
    },
	components: {
        CollapsePanel,
		PaginatedChips
	},
	data: () => ({}),
	created() {},
	mounted() {
	},
	computed: {
		getTitle(){
			return this.item.title;
		},
		getIcon(){
			return this.item.icon;
		},
		isRequired(){
			return this.item.required;
		},
		isLoading(){
			return this.loadings[this.item.key];
		},
		getIdentifier(){
			return String(this.id);
		},
		getExpandFilterIcon(){
			if(this.showFilter)return this.icon.filter.value;
		},
		getExpandFilterText(){
			if(this.showFilter)return this.icon.filter.label;
		},
		getExpandClearFilterIcon(){
			if(this.showFilter)return this.icon.clearFilter.value;
		},
		getExpandClearFilterText(){
			if(this.showFilter)return this.icon.clearFilter.label;
		}
	},
	methods: {
		openFilters(){
			this.$emit('handler-modal-filter', !this.showModalFilter);
		},

		clearAllFilters(){
			this.$emit('remove-all');
		}
	},
	watch: {},
});