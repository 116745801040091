import Vue from "vue";
import {
	getDefaultSortingOption,
	getPaginatedDefault,
} from "@/utils/filter-global";
import GridTable from "@/components/Header/Tables/GridTable.vue";
import FilterGridAG from "@/components/Header/Tables/FilterGridAG.vue";
import ActiveFieldTable from "@/components/Header/Tables/ActiveFieldTable.vue";
import { SortingOption, ResultPaginate } from "@/interfaces/paginated";
import { preparedColumnDefsGrid } from "@/utils/CustomizeViewData";
import { mapActions } from "vuex";
import { isEmpty, isNull } from "lodash";
import ParamService from "@/services/params-service";
import { SchedulerReportFilters } from "@/interfaces/report";
import Separator from "@/components/Content/Separator.vue";
import CardAction from "@/components/Content/CardAction.vue";
import ActionsTable from "@/components/Header/Tables/ActionsTable.vue";
import ConfirmDeleteReport from "@/views/Admin/Reports/Form/ConfirmDeleteReport.vue";
import { getPermisionReport } from "@/utils/permissionResolve";

const DELAY_FETCH: number = 7000;

export default Vue.extend({
	name: "SchedulerList",
	props: {
		showTitle: {
			type: Boolean,
			default: true,
		},
	},
	components: {
		GridTable,
		FilterGridAG,
		ActiveFieldTable,
		Separator,
		CardAction,
		ActionsTable,
		ConfirmDeleteReport,
	},
	data: () => ({
		paginated: getPaginatedDefault(1, 5),
		filters: {},
		options: getDefaultSortingOption("id"),
		context: null,
		selectionRows: "single",
		tableLoading: false,
		formDelete: {
			all: false,
			id: null,
			open: false,
			active: false,
			ids: null,
			activeData: {
				id: null,
				status: false,
				rowIndex: null,
			},
		},
	}),
	created() {
		this.$nextTick(async () => {
			this.context = { componentParent: this };
			await this.getPaginated();
		});
	},
	beforeDestroy() {
		this.clearIntervalData();
	},
	async mounted() {
		this.fetchPaginate(DELAY_FETCH);
	},
	computed: {
		showListReport() {
			return !isEmpty(this.getSchedulerReport);
		},

		getResultPaginate(): ResultPaginate {
			return this.$store.state.report.result_paginate;
		},

		prepareTableHeaders() {
			return [
				{
					text: this.$t("report.scheduler.table.subject"),
					align: "start",
					sortable: false,
					filterable: false,
					value: "email_subject",
				},
				{
					text: this.$t("report.scheduler.table.report_type"),
					align: "start",
					sortable: false,
					filterable: false,
					value: "report_type",
				},
				{
					text: this.$t("report.scheduler.table.start_date"),
					align: "start",
					sortable: false,
					filterable: false,
					value: "start_date",
				},
				{
					text: this.$t("report.scheduler.table.end_date"),
					align: "start",
					sortable: false,
					filterable: false,
					value: "end_date",
				},
				{
					text: this.$t("report.scheduler.table.status"),
					align: "start",
					sortable: false,
					filterable: false,
					value: "active",
				},
				{
					text: this.$t("report.scheduler.table.created_user"),
					align: "start",
					sortable: false,
					filterable: false,
					value: "user_name",
				},
			];
		},

		getColumnsDef() {
			return preparedColumnDefsGrid(
				this.prepareTableHeaders,
				this.getConfigColumnDef
			);
		},

		getConfigColumnDef() {
			return {
				context: this.context,
				entityName: "Scheduler_Report_List",
				redirect: "ScruderReportEdit",
				checkDisableEdit: true,
				edit: true,
				delete: true,
				editReport: true,
				eventActive: true,
				minWidthActions: 150,
				maxWidthActions: 150,
				resizableID: true,
				permission: this.getPermission
			};
		},

		gerRowsData(): any[] {
			return this.getSchedulerReport || [];
		},

		// hasPendingRowsData() {
		// 	const rows: any[] = this.gerRowsData;
		// 	return rows?.some((r) => r.status === Status.PENDING);
		// },

		getSchedulerReport(): any[] {
			const result: ResultPaginate | undefined = this.getResultPaginate;
			if (!result || !result.data) return [];
			return result.data;
		},

		getPermission(){
			return getPermisionReport();
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("report", ["getSchedulerPaginated"]),
		...mapActions("report_v2", ["setState"]),

		clearIntervalData() {
			clearInterval(this.intervalId);
		},

		async fetchPaginate(interval: number = 10000) {
			/**
			 * Limpiar intervalId
			 */
			this.clearIntervalData();
			/**
			 * Si hay elementos con status pending se llamara cada {interval} segundos
			 */
			//if (this.hasPendingRowsData) {
			this.intervalId = setInterval(async () => {
				await this.getPaginated();
			}, interval); // Ejecuta la función cada {interval} segundos
			//}
		},

		updatePaginate(data: any) {
			this.paginated.page = data;
		},
		getActiveText(active: Boolean) {
			return active
				? this.$t("common.fields.active")
				: this.$t("common.fields.inactive");
		},
		async cancelDelete(params: {
			activeActions: boolean;
			id: any;
			status: any;
			rowIndex: any;
			rever: Boolean;
		}) {
			this.formDelete.open = false;
			this.formDelete.all = false;
			this.formDelete.id = null;
			this.formDelete.active = false;
			(this.formDelete.ids = null),
				(this.formDelete.activeData.id = null);
			this.formDelete.activeData.status = false;
			this.formDelete.activeData.rowIndex = null;

			if (!isNull(params) && params.activeActions && params.rever) {
				let report = this.getSchedulerReport.find(
					({ id }) => id == params.id
				);
				report.status = !params.status;
				this.$refs.Grid_Line.updateRowNodeByIndex(
					params.rowIndex,
					report
				);
			}
		},
		async handleAction(action: { type: any }) {
			try {
				switch (action.type) {
					case "delete-all":
						await this.handleDeleteAll();
						break;
				}
				await this.setLoadingData();
			} catch (error) {
				await this.setLoadingData();
			}
		},
		async deleteReportListItem(id: any) {
			return await this.$store.dispatch("report/delete", id, {
				root: true,
			});
		},
		async deleteAllReportItem(id: any) {
			return await this.$store.dispatch("report/deleteAll", id, {
				root: true,
			});
		},
		async getPaginated() {
			try {
				//this.tableLoading = true;

				await this.getSchedulerPaginated(
					await ParamService.getParams(
						this.paginated,
						this.filters,
						this.options
					)
				);
				// Guardar en el store
				//this.setState({ key: "table_report", value: response.data });
				this.tableLoading = false;
			} catch (error) {
				this.tableLoading = false;
			}
		},

		async setFilter(params: { key: string | number; value: any }) {
			this.filters = {};
			this.filters[params.key] =
				typeof params.value !== "undefined" ? params.value : "";
		},

		async selectedOption(params: { options: SortingOption; filter: any }) {
			this.setFilter({ key: params.options.sort, value: params.filter });
			this.updatePaginate(1);
			await this.updateParams({
				filters: this.filters,
				options: params.options,
			});
		},

		async updateParams(params: {
			filters: SchedulerReportFilters;
			options: SortingOption;
		}) {
			this.filters = params.filters;
			this.options = params.options;
			this.updatePaginate(1);
			await this.getPaginated();
		},

		async removeOption() {
			this.filters = {};
			this.options = getDefaultSortingOption("id");
			this.updatePaginate(1);
			await this.getPaginated();
		},

		async updateTable() {
			await this.getPaginated();
		},
		async deleteReportByID(params: any) {
			this.formDelete.open = true;
			this.formDelete.all = false;
			this.formDelete.id = params.id;
		},
		async handleDeleteAll() {
			let values: number[] = this.getSchedulerReport.map(
				(r: { id: number }) => r.id
			);
			let obj = {
				ids: values,
			};
			this.formDelete.open = true;
			this.formDelete.all = true;
			this.formDelete.ids = obj;
		},
		async updateList() {
			await this.getPaginated();
		},
		async handleActive(params: any) {
			this.formDelete.open = true;
			this.formDelete.all = true;
			this.formDelete.active = true;
			this.formDelete.activeData.id = params.id;
			this.formDelete.activeData.status = params.active;
			this.formDelete.activeData.rowIndex = params.rowIndex;
		},
		async handleDelete(index: any) {
			let item = this.page.data[index];
			if (!!item.id) {
				delete item.editing;
				this.page.data.splice(index, 1, { ...item, deleted: true });
			} else {
				this.page.data.splice(index, 1);
			}
		},

		async EditReportMethod(params: any) {
			this.$emit("update-Report", params);
		},
	},
	watch: {
		"paginated.page"(val, old) {
			if (val !== old) {
				this.getPaginated();
			}
		},
	},
});
