import { render, staticRenderFns } from "./ButtonCard.vue?vue&type=template&id=1f0997f4"
import script from "@/views/Admin/Reports/V2/Dependencies/Button/ButtonCardLogic.ts?vue&type=script&lang=ts&external"
export * from "@/views/Admin/Reports/V2/Dependencies/Button/ButtonCardLogic.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports