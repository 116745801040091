import Vue from "vue";
import { getPermisionReport } from "@/utils/permissionResolve";
import { mapGetters } from "vuex";

export default Vue.extend({
	name: "CardAction",
	props: {
		item: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	components: {},
	data: () => ({}),
	created() {},
	mounted() {},
	computed: {
		...mapGetters("profile", ["getAbility"]),

		getTitle() {
			return this.item.title;
		},

		getIcon() {
			return this.item.icon;
		},

		isLoading() {
			return this.item.loading;
		},

		getPermission(){
			return getPermisionReport();
		},

		canCreate(){
			return this.getAbility.can(this.getPermission.actions.create_sp, this.getPermission.subject);
		},
	},
	methods: {
		async handleAction() {
			this.$emit("action", { type: 'run' });
		},
	},
	watch: {},
});