
//@ts-nocheck
import Vue from "vue";
import Alertize from "@/components/Alertize.vue";
import Separator from "@/components/Content/Separator.vue";
import CardSelect from "@/components/Content/CardSelect.vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardSearcher from "@/components/Content/CardSearcher.vue";
import CardChip from "@/components/Content/CardChip.vue";
import CardList from "@/components/Content/CardList.vue";
import CardGroupList from "@/components/Content/CardGroupList.vue";
import CardActions from "@/components/Content/CardActions.vue";
import SchedulerReportEdit from "@/views/Admin/Reports/Form/SchedulerReportEdit.vue";
import SchedulerForm from "@/views/Admin/Reports/Form/SchedulerReportForm.vue";
import SchedulerReportList from "@/views/Admin/Reports/Form/SchedulerReportList.vue";
import ReportType from "@/views/Admin/Reports/V2/Form/Sections/ReportType/index.vue";
import ReportFilters from "@/views/Admin/Reports/V2/Form/Sections/ReportFilters/index.vue";
import { getError } from "@/utils/resolveObjectArray";
import { prepareReportToSP } from "@/utils/reportData";
import {
	isRequired,
	isAfterCompare,
	isMaxDays,
} from "@/services/rule-services";
import { SectionType } from "@/interfaces/report";
import { debounce, find, includes, isEmpty } from "lodash";
import DatePicker from "@/components/Content/DatePicker.vue";
import DataPickerStarTime from "@/components/Content/DataPickerStarTime.vue";
import { mapActions, mapGetters } from "vuex";
import { TypeLoading } from "@/interfaces/loading";
import { scrollToTop } from "@/utils/services-global";
import {
	GetDataPanels,
	GetButtonRunReport,
} from "@/views/Admin/Reports/V2/utils/reportsUtils";
import { ActivePanelTab } from "@/interfaces/report";
import CardCollapseItem from "@/views/Admin/Reports/V2/Dependencies/Panels/index.vue";
import PaginatedChips from "@/views/Admin/Reports/V2/Dependencies/Chips/PaginatedIndex.vue";
import ModalFilters from "@/views/Admin/Reports/V2/Dependencies/Modal/index.vue";
import ListData from "@/views/Admin/Reports/V2/Dependencies/ListData/index.vue";
import ButtonCard from "@/views/Admin/Reports/V2/Dependencies/Button/ButtonCard.vue";
import { Notification, MessageTypes } from "@/interfaces/proccess";
import { getPermisionReport } from "@/utils/permissionResolve";
import NotPermission from "@/views/Errors/not_permission.vue";
import { ReportDataEntity, ResourceDataEntity } from "@/interfaces/report";
import {
	ScheduleEntity,
	ReportResourceDataEntity,
} from "@/models/Reports/v2/Entity";
import { ResponseReportEntity } from "@/models/Reports/v2/GenerateReport";
import CountDownTimer from "@/components/Content/CountDownTimer/index.vue";
import { getValidityLimitSeg } from "@/utils/env";
import Snacktify from "@/components/Commons/Snacktify/index.vue";
import { EnumReportType } from "@/interfaces/report";

const DEFAULT_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export default Vue.extend({
	name: "ReportFormAll",
	props: {
		isSchedulerReport: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Alertize,
		Separator,
		CardSelect,
		CardSearcher,
		CardTextField,
		CardChip,
		CardList,
		CardActions,
		DatePicker,
		DataPickerStarTime,
		CardGroupList,
		SchedulerForm,
		SchedulerReportList,
		SchedulerReportEdit,
		CardCollapseItem,
		PaginatedChips,
		ModalFilters,
		ListData,
		ButtonCard,
		NotPermission,
		ReportType,
		ReportFilters,
		CountDownTimer,
		Snacktify,
	},
	data: () => ({
		valid: true,
		isRunOver: false,
		enabledScheduler: false,
		showFormScheduler: false,
		showModalFilter: false,
		showFormEditScheduler: false,
		snackbar: false,
		texto_snackbar: "",
		selectedItem: [],
		activePanel: [
			ActivePanelTab.REPORT_TYPE,
			ActivePanelTab.FILTERS,
			ActivePanelTab.DIMENSIONS,
			ActivePanelTab.METRICS,
		],

		accountTerm: "",
		advertiserTerm: "",
		lineItemTerm: "",
		campaignTerm: "",
		creativeTerm: "",
		dealIdTerm: "",
		metricTerm: "",
		dimensionTerm: "",

		advertisers: [],

		dataReportEdit: {},

		buttonRun: GetButtonRunReport(),

		limitValidity: {
			totalLimit: getValidityLimitSeg,
			totalLimitRemaining: getValidityLimitSeg,
		},
	}),
	created() {
		this.$nextTick(async () => {
			try {
				this.setLoadingData(TypeLoading.loading);
				await this.fetchResource(
					"report_format_types_sp",
					"report_all_type",
					false
				);
				await this.fetchResource(
					"report_data_range_sp",
					"data_range",
					false
				);
				await this.fetchResource("report_format_types", "type", false);
				await this.fetchDimensionsAndMetrics();
				await this.checkAccountMaster();
				await this.setLoadingData();
			} catch (error) {
				await this.setLoadingData();
			}
		});
	},
	async mounted() {},
	computed: {
		...mapGetters("report", ["getState"]),
		...mapGetters("account", ["isAccountMaster"]),
		...mapGetters("profile", ["getAbility"]),
		...mapGetters("report_v2", [
			"storeReport",
			"storeSchedule",
			"storeResources",
			"storeResourceData",
		]),
		...mapGetters("generate_report", ["attempResponse"]),

		getAttempResponse(): ResponseReportEntity {
			return this.attempResponse;
		},
		getStoreReport(): ReportDataEntity {
			return this.storeReport;
		},
		getStoreResources(): ResourceDataEntity {
			return this.storeResources;
		},
		getStoreResourceData(): ReportResourceDataEntity {
			return this.storeResourceData;
		},
		getStoreSchedule(): ScheduleEntity {
			return this.storeSchedule;
		},

		getPanelsItems() {
			let panels = GetDataPanels();

			const isEdit: Boolean = this.getStoreSchedule.form.hasID();

			/**
			 *  Si el tab es de scheduler {isSchedulerReport}
			 * 	Modo edicion {isEdit}
			 */
			if (this.isSchedulerReport && !isEdit) return [];

			return panels;
		},

		getErrors() {
			return this.$store.state.proccess.errors;
		},

		getDataRangeSelected() {
			return this.getStoreResources.data_range.find(
				(d) => d.id == this.getStoreReport.data_range
			)?.value;
		},

		getDataRange() {
			return {
				data_range: this.getDataRangeSelected,
				start_date: this.getStoreReport.start_date,
				end_date: this.getStoreReport.end_date,
			};
		},

		getRules() {
			return {
				isRequired,
				isAfterCompare,
				isMaxDays,
			};
		},

		getMetricSP() {
			return this.getStoreResources.metrics;
		},

		getDimensionSP() {
			return this.getStoreResources.dimensions;
		},

		isCustom() {
			return this.getStoreReport.data_range === "custom";
		},

		getReportTypeHint() {
			if (this.getStoreReport.isSelected(EnumReportType.XLS)) {
				return this.$t("report.legend.extension", {
					extension: "xlsx",
					limit: "27MB",
				});
			}
			return this.$t("report.report_format");
		},

		getMaxDate() {
			return this.moment().format(DEFAULT_DATE_TIME_FORMAT);
		},

		getFilters() {
			return this.getStoreReport.filters;
		},

		getButtunRun() {
			return this.buttonRun;
		},

		getPermission() {
			return getPermisionReport();
		},

		canReportTypeSP() {
			return this.getAbility.can(
				this.getPermission.actions.index_report_sp,
				this.getPermission.subject
			);
		},

		canAccountSP() {
			return this.getAbility.can(
				this.getPermission.actions.index_account_sp,
				this.getPermission.subject
			);
		},

		canDataRangeSP() {
			return this.getAbility.can(
				this.getPermission.actions.index_data_sp,
				this.getPermission.subject
			);
		},

		canFiltersSP() {
			return this.getAbility.can(
				this.getPermission.actions.show_filter_sp,
				this.getPermission.subject
			);
		},

		canMetricAndDimSP() {
			return this.getAbility.can(
				this.getPermission.actions.show_metrics_dim_sp,
				this.getPermission.subject
			);
		},

		filteredReportFormats() {
			const resources: ResourceDataEntity = this.getStoreResources;
			return resources.type;
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("account", ["getAccount"]),
		...mapActions("report", [
			"postDataService",
			"setState",
			"attemptGetReport",
			"download",
			"prepareConfigReportAttempt",
			"downloadReport",
			"delete",
			"deleteAll",
		]),
		...mapActions("report_v2", ["setScheduleFilters"]),
		...mapActions("generate_report", ["generateReport"]),

		showItem(type: SectionType, compare: SectionType): boolean {
			return type === compare;
		},

		async checkAccountMaster() {
			if (this.canAccountSP) {
				const account = await this.getAccount();
				if (account.master_account) {
					await this.handleGetAccountSP();
					this.getStoreReport.filters.account = account
						? [{ key: account.external_id, value: account.name }]
						: [];
				}
			}
		},

		handlerModalFilter(toggle: boolean) {
			this.showModalFilter = toggle;
		},

		setNotification(notification: Notification) {
			return this.$store.dispatch(
				"proccess/setNotification",
				notification,
				{
					root: true,
				}
			);
		},

		getMsgSuccess(message: string) {
			let msgSucces = {
				title: MessageTypes.TITLE_SUCCESS,
				message: message,
				type: MessageTypes.SUCCESS,
				show: true,
				details: "",
				btn_text: "close",
			};
			return msgSucces;
		},

		async toggleStatus(type: string, status: Boolean = false) {
			try {
				this[type] = status;

				if (type === "enabledScheduler") {
					this.$emit("enabled-tab-scheduler", !status);
				}
			} catch (error) {
				console.error("toggleStatus", error);
			}
		},

		async clearErr(key) {
			if (this.getErrors.hasOwnProperty(key)) {
				this.getErrors[key] = [];
			}
		},

		async validate() {
			return await this.$refs.form.validate();
		},

		async handlefetchReport() {
			try {
				if (!(await this.validate())) return;

				this.snackbar = false;
				this.texto_snackbar = "";

				if (this.isAccountMaster) {
					if (isEmpty(this.getStoreReport.filters.account)) {
						this.snackbar = true;
						this.texto_snackbar = this.$t(
							"report.messages.account"
						);
						return;
					}
				}

				if (isEmpty(this.getStoreReport.filters.campaigns)) {
					this.snackbar = true;
					this.texto_snackbar = this.$t("report.messages.campaign");
					return;
				}

				if (isEmpty(this.getStoreReport.dimensions)) {
					this.snackbar = true;
					this.texto_snackbar = this.$t("report.messages.dimensions");
					return;
				}

				if (isEmpty(this.getStoreReport.metrics)) {
					this.snackbar = true;
					this.texto_snackbar = this.$t("report.messages.metrics");
					return;
				}

				this.snackbar = false;
				this.texto_snackbar = "";

				const payload = await prepareReportToSP(this.getStoreReport);

				const response = await this.generateReport({
					reportType: payload.report_type,
					extension: payload.type,
					payload,
				});

				await this.toggleStatus("enabledScheduler", response.success);
			} catch (error) {
				console.error(`${this.$options.name}::handlefetchReport`, {
					error,
				});
			}
		},

		async handleCombo(key: string) {
			if (this.isAccountMaster) {
				if (isEmpty(this.getStoreReport.filters.account)) {
					this.snackbar = true;
					this.texto_snackbar = this.$t("report.messages.account");
					return;
				}
			}
			this.snackbar = false;
			this.texto_snackbar = "";
			this.$emit("fetch-combo", key);
		},

		getError(index: any) {
			return getError(this.getErrors, index);
		},

		syncData(key: string, term: string) {
			this[`${key}Term`] = term;
		},

		handleRemove(event: any) {
			this.$emit("remove-item", event);
		},

		async handlerFocus(event: any) {
			const { key } = event;
			const emitterKey: string =
				key === "account" ? "get-account-sp" : "fetch-combo";

			const invalidAccount: Boolean =
				this.isAccountMaster &&
				key !== "account" &&
				isEmpty(this.getStoreReport.filters.account);

			if (invalidAccount) {
				this.snackbar = true;
				this.texto_snackbar = this.$t("report.messages.account");
			} else {
				this.$emit(emitterKey, key);
			}
		},

		async handleGetAccountSP() {
			this.$emit("get-account-sp");
		},

		async isSelectedAll(value: Array<number>) {
			return includes(value, 0);
		},

		async handleAll(params: { key: any; value: Array<any> }) {
			this.getStoreResourceData.selected_all[params.key] = params.value;

			const items: Array<any> = (await this.isSelectedAll(params.value))
				? this.getStoreResources[params.key]
				: [];

			this.getStoreReport.filters[params.key] = items;
		},

		async handleSelectedAll(key: any, value: Array<any>) {
			this.getStoreResourceData.selected_all[key] = value;

			const items: Array<any> = (await this.isSelectedAll(value))
				? this.getStoreResources[key]
				: [];

			this.getStoreReport.filters[key] = items;
		},

		/**
		 * Actualizar el valor selected_all
		 * Detectar si todos los elementos fueron seleccionados
		 * @param key
		 * @param value
		 */
		async handleSelectedItem(key: any, value: Array<any>) {
			const equal = this.getStoreResources[key]?.length === value?.length;
			if (value?.length == 0) {
				this.getStoreResourceData.selected_all[key] = [];
			} else {
				this.getStoreResourceData.selected_all[key] = equal ? [0] : [];
			}
		},

		/**
		 *
		 * Fetch resources
		 *
		 * @param key
		 * @param value
		 * @param field
		 * @event $emit
		 */
		async fetchResource(
			key: string,
			field: string,
			addParams: boolean = true,
			custom_key: string = "extra",
			custom_value: string = "description"
		) {
			const params = {
				resource: key,
				field: field,
				addParams: addParams,
				custom_key: custom_key,
				custom_value: custom_value,
			};
			if (key == "report_format_types_sp") {
				if (this.canReportTypeSP) {
					await this.emitRecource(params);
				}
				return;
			}
			if (key == "report_data_range_sp") {
				if (this.canDataRangeSP) {
					await this.emitRecource(params);
				}
				return;
			}
			await this.emitRecource(params);
		},

		async emitRecource(params: any) {
			this.$emit(`fetch-resource`, params);
		},

		async fetchFields(field_type: string) {
			this.$emit(`fetch-fields`, { field_type });
		},

		async fetchAllReportType() {
			this.$emit(`fetch-all-report-type`);
		},

		async fetchDimensionsAndMetrics() {
			if (this.canMetricAndDimSP) {
				this.$emit(`fetch-dims-metr`);
			}
		},

		/**
		 * @deprecated
		 */
		async fetchDimension(field_type: string) {
			this.$emit(`fetch-dim-met`, { field_type });
		},

		/**
		 * @deprecated
		 */
		async fetchMetricas(field_type: string) {
			this.$emit(`fetch-dim-met`, { field_type });
		},

		async fetchSchedulerTypes(field_type: string) {
			this.$emit("fetch-scheduler-type", field_type);
		},

		getDisplayName(type: string, item: number) {
			const finded = find(this[type], function (t) {
				t.id == item;
			});
			return finded?.value;
		},

		/**HANDLERS */

		/**
		 * From CardActions - line item
		 * @param action
		 */
		async handleAction(action: { type: any }) {
			try {
				this.setLoadingData(TypeLoading.loading);
				switch (action.type) {
					case "run":
						await this.handlefetchReport();
						break;
					case "scheduled-report":
						await this.toggleStatus(
							"showFormScheduler",
							this.enabledScheduler
						);
						break;
					case "edit":
						await this.toggleStatus("showFormEditScheduler", true);
						break;
				}
				await this.setLoadingData();
			} catch (error) {
				await this.setLoadingData();
			}
		},

		async updateList() {
			this.$refs.shcedulerList?.updateTable();
		},

		async clearFilters(params: any) {
			this.$emit("clear-all-filter", params);
		},

		async handleClearFilters(params: any) {
			this.$emit("handle-clear", params);
		},

		async updateAccount(params) {
			this.$emit("update-account", params);
		},

		async checkSelectedAll(params: string) {
			if (
				this.getStoreResources[params].length ==
				this.getStoreReport.filters[params].length
			) {
				this.getStoreResourceData.selected_all[params] = [0];
			} else {
				this.getStoreResourceData.selected_all[params] = [];
			}
		},

		async updateReport(params: any) {
			this.getStoreResourceData.hidden_fields.date = true;
			this.getStoreResourceData.hidden_fields.format = true;
			this.getStoreResourceData.hidden_fields.run = true;
			this.getStoreResourceData.hidden_fields.scheduler = true;
			this.enabledScheduler = true;
			this.showFormScheduler = true;
			this.dataReportEdit = params;
			this.$emit("update-Report", params);
			scrollToTop();
		},

		async hideReportScheduler() {
			this.enabledScheduler = false;
			this.showFormScheduler = false;
			this.getStoreResourceData.hidden_fields.date = false;
			this.getStoreResourceData.hidden_fields.format = false;
			this.getStoreResourceData.hidden_fields.run = false;
			this.getStoreResourceData.hidden_fields.scheduler = false;
			this.$emit("clear-all-resourse");
			await this.updateList();
		},

		async setItemSelected(params: any) {
			this.$emit("set-selected-item", params);
		},

		async handleRemoveAll() {
			//Cuando se eliminan todos los filtros
			//Se vuelve a verificar si es cuenta master
			this.clearFilters({ account: true, dm: false, force: true });
			await this.checkAccountMaster();
		},

		async handleClearForm() {
			this.$emit("clear-all");
		},

		async setAccountData(_account: any[] | null) {
			if (isEmpty(_account)) {
				this.getStoreReport.filters.account = [];
			} else {
				this.getStoreReport.filters.account = [];
				this.getStoreReport.filters.account.push({
					key: _account.key,
					value: _account.value,
				});
			}
		},

		handleStopCountDown() {
			console.log("handleStopCountDown");
		},

		handleRemaining(val: number) {
			this.limitValidity.totalLimitRemaining = val;
		},
	},
	watch: {
		advertiserTerm: debounce(async function (val: string) {
			//if (val.length < 2) return;
			this.$emit("update-term", {
				type: "advertiser",
				term: val,
			});
		}, 500),

		lineItemTerm: debounce(async function (val: string) {
			//if (val.length < 2) return;
			this.$emit("update-term", {
				type: "line_item",
				term: val,
			});
		}, 500),

		campaignTerm: debounce(async function (val: string) {
			//if (val.length < 2) return;
			this.$emit("update-term", {
				type: "campaign",
				term: val,
			});
		}, 500),

		creativeTerm: debounce(async function (val: string) {
			//if (val.length < 2) return;
			this.$emit("update-term", {
				type: "creative",
				term: val,
			});
		}, 500),

		accountTerm: debounce(async function (val: string) {
			//if (val.length < 2) return;
			this.$emit("update-term", {
				type: "account",
				term: val,
			});
		}, 500),

		getStoreReport: {
			handler(val, oldVal) {
				/**
				 * Reset para la respuesta de reporte generado
				 */
				this.getAttempResponse.reset();
			},
			deep: true,
			immediate: false,
		},

		/**
		 * Watch: advertisers
		 * @param val
		 */
		async "getStoreReport.filters.advertisers"(val) {
			this.handleSelectedItem("advertisers", val);
		},

		/**
		 * Watch: line_items
		 * @param val
		 */
		async "getStoreReport.filters.line_items"(val) {
			this.handleSelectedItem("line_items", val);
		},

		/**
		 * Watch: campaigns
		 * @param val
		 */
		async "getStoreReport.filters.campaigns"(val) {
			this.handleSelectedItem("campaigns", val);
		},

		/**
		 * Watch: creatives
		 * @param val
		 */
		async "getStoreReport.filters.creatives"(val) {
			this.handleSelectedItem("creatives", val);
		},

		/**
		 * Watch: account
		 * @param val
		 */
		async "getStoreReport.filters.account"(val) {
			this.clearFilters({ account: false, dm: false });
		},

		/**
		 * Watch: deal id
		 * @param val
		 */
		async "getStoreReport.filters.deal_id"(val) {
			this.handleSelectedItem("deal_id", val);
		},

		/**
		 * Watch: report type
		 * @param val
		 */
		async "getStoreReport.report_type"(val) {
			this.clearFilters({ account: true, dm: true });
			await this.fetchDimensionsAndMetrics();
			await this.checkAccountMaster();
		},

		async "getStoreReport.start_date"(val) {
			if (val) {
				this.clearErr("start_date");
			}
		},

		async "getStoreReport.end_date"(val) {
			if (val) {
				this.clearErr("end_date");
			}
		},

		/**
		 * Watch: resources
		 * @param val
		 */
		async "resources.advertisers"(val) {
			this.checkSelectedAll("advertisers");
		},

		async "resources.campaigns"(val) {
			this.checkSelectedAll("campaigns");
		},

		async "resources.line_items"(val) {
			this.checkSelectedAll("line_items");
		},

		async "resources.creatives"(val) {
			this.checkSelectedAll("creatives");
		},

		async "resources.deal_id"(val) {
			this.checkSelectedAll("deal_id");
		},

		"limitValidity.totalLimitRemaining"(val: number) {
			if (val < 1) {
				this.getAttempResponse.reset();
			}
		},
	},
});
