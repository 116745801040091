import Vue from "vue";
import { isUndefined, isEmpty } from "lodash";
import moment from "moment-timezone";
import i18n from "@/plugins/i18n";

const DEFAULT_MOMENT_FORMAT = "YYYY-MM-DD";

export default Vue.extend({
	name: "DateCardModal",

	props: {
		item: {
			type: Object,
			default: function () {
				return {};
			},
		},
        groupStyles: {
			type: String,
			default: "",
		},
		groupClass: {
			type: String,
			default: "",
		},
		canClose: {
			type: Boolean,
			default: false,
		},
		column: {
			type: Boolean,
			default: false,
		},
        custom: {
			type: Boolean,
			default: false,
		},
	},

	components: {},

	data: () => ({}),

	created() {},

	async mounted() {
		this.$nextTick(async () => {});
	},

	computed: {
		getDates() {
			return this.item;
		},
        getTitle(){
            return "Date Range";
        },
        isCustom(){
            return this.custom;
        },
        getStartDate(){
            return moment(this.item.start_date).format(DEFAULT_MOMENT_FORMAT);
        },
        getEndDate(){
            return moment(this.item.end_date).format(DEFAULT_MOMENT_FORMAT);
        },
		getDataRange(){
            return isEmpty(this.item.data_range) ? i18n.t('report.messages.data_range') : this.item.data_range
        }
	},

	methods: {},

	watch: {},
});