
	import Vue from "vue";

	export default Vue.extend({
		name: "TabsSubMenu",
		props: {
			items: {
				type: Array,
				default: function () {
					return [];
				},
			},
			current_tab: {
				type: Number,
				default: 0,
			},
		},
		components: {},
		data: () => ({}),
		created() {},
		async mounted() {},
		computed: {
			currentTab: {
				set(val) {
					this.$emit("update-selected-tab-index", val);
				},
				get() {
					return this.current_tab;
				},
			},
		},
		methods: {},
	});
