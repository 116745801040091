import { DataItem, DataItemName, ResourceDimension } from "@/interfaces/report";

export function getParsedData(data: any) {
	return JSON.parse(data);
}

// Función para filtrar y obtener los datos de report_dimensions
export async function getFilteredDimensions(
	report_dimensions: ResourceDimension,
	dimensionData: string[]
) {
	let filteredDimensions: DataItem[] = [];

	Object.entries(report_dimensions).forEach(([key, value]) => {
		const filteredItems = value.filter((item) =>
			dimensionData.includes(item.id)
		);
		filteredItems.forEach((item: DataItemName) => {
			filteredDimensions.push({ id: item.id, value: item.name });
		});
	});

	return filteredDimensions;
}

export async function getFilteredMetrics(
	report_metrics: DataItem[],
	metricData: string[]
) {
	return report_metrics.filter((r) => metricData.includes(r.id));
}

export async function getFilteredSplits(
	property: string,
	resource: DataItem[]
) {
	const propertyIds = property.split(",");
	return resource.filter((r) => propertyIds.includes(r.id));
}

export async function getFilteredArray(
	property: number[],
	resource: DataItem[]
) {
	return resource.filter((r) => property.includes(Number(r.id)));
}

export function hasProperties(params?: any) {
	return (
		typeof params === "object" &&
		params !== undefined &&
		Object.keys(params).length > 0
	);
}
